import { FormEvent } from 'react';
import { useInputState, InputState } from 'hooks/useInputState';

export type Props = {
  onSubmit(input: LoginCredentials): void;
};

export type LoginCredentials = {
  email: string;
  password: string;
};

type Results = {
  inputState: InputState<LoginCredentials>;
  handleSubmit(e: FormEvent<HTMLFormElement>): void;
  isValidInput: boolean;
};

export const useLoginForm = ({ onSubmit }: Props): Results => {
  const inputState = useInputState<LoginCredentials>({
    email: '',
    password: '',
  });
  const [, , inputObject] = inputState;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(inputObject);
  };

  const { email, password } = inputObject;
  const isValidInput = !!(email && password);

  return { inputState, handleSubmit, isValidInput };
};
