import { TextField, SubmitButton } from 'components/form-input';
import { useScreenSize } from 'hooks/useScreenSize';
import { useLoginForm, Props } from './useLoginForm';

export const LoginForm = ({
  onSubmit,
  waiting,
}: Props & { waiting: boolean }): JSX.Element => {
  const { inputState, isValidInput, handleSubmit } = useLoginForm({
    onSubmit,
  });
  const { upSm } = useScreenSize();
  const inputMarginY = upSm ? 20 : 5;

  return (
    <div
      style={{ textAlign: 'center', padding: upSm ? 20 : 5, marginBottom: 15 }}
    >
      <form onSubmit={handleSubmit}>
        <div style={{ margin: `20px ${inputMarginY}px` }}>
          <TextField
            name="email"
            label="メールアドレス"
            type="email"
            inputState={inputState}
          />
        </div>
        <div style={{ margin: `20px ${inputMarginY}px` }}>
          <TextField
            name="password"
            label="パスワード"
            type="password"
            inputState={inputState}
          />
        </div>
        <SubmitButton disabled={!isValidInput} waiting={waiting}>
          ログイン
        </SubmitButton>
      </form>
    </div>
  );
};
