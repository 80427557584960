import { useState } from 'react';

export type InputState<T> = [
  getValue: (name: string) => unknown,
  setValue: (name: string, value: unknown) => void,
  inputObject: T,
];

export function useInputState<T extends { [name: string]: unknown }>(
  initialValue: T,
): InputState<T> {
  const [input, setInput] = useState<T>(initialValue);

  const getValue = (name: string) => input[name];

  const setValue = (name: string, value: unknown) => {
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  return [getValue, setValue, input];
}
