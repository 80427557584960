import { useEffect } from 'react';
import Router from 'next/router';
import Link from 'next/link';
import Image from 'next/image';
import { useSession } from 'next-auth/client';
import { Container, Paper, Box, Divider, makeStyles } from '@material-ui/core';
import { HeadPageTitle } from 'components/HeadPageTitle';
import { H1 } from 'components/presentation';
import { Login } from 'page-components/company/login/Login';
import { setUuids } from '../../lib/uuid';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: '#ccc',
    minHeight: '100vh',
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  lineLoginLink: {
    marginTop: '1.5rem',
    textAlign: 'right',
  },
  passwordForgotLink: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
  },
}));

const LoginPage = (): JSX.Element => {
  const title = 'ログイン';
  const classes = useStyles();
  const [session, loading] = useSession();

  useEffect(() => {
    if (session && session.user.role === 'company-member') {
      if (session.user.company?.uuid && session.user.uuid) {
        setUuids(session.user.uuid, session.user.company.uuid);
      }
      Router.push('/');
    }
  }, [session]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className={classes.root}>
      <HeadPageTitle title={title} />
      <Container maxWidth="sm">
        <Paper className={classes.paper}>
          <Image
            priority
            src="/images/crassone_biz_logo.svg"
            width={294.7}
            height={42.4}
          />
          <H1>{title}</H1>
          <Login />
          <Divider />
          <Box className={classes.lineLoginLink}>
            <Link href={'login/line/sign-in'}>
              LINEアカウントでログインする
            </Link>
          </Box>
          <Box className={classes.passwordForgotLink}>
            <Link href={'members/password/forgot'}>
              パスワードを忘れた方はこちら
            </Link>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default LoginPage;
