import { useState } from 'react';
import { signIn } from 'next-auth/client';
import { LoginForm, LoginCredentials } from 'components/LoginForm';
import { Snackbar } from 'components/notification';

// { redirect: false } の時はこれが返ってくる
// @types/next-auth がうまく対応できてない
// https://next-auth.js.org/getting-started/client#using-the-redirect-false-option
interface SignInResponse {
  /**
   * Will be different error codes,
   * depending on the type of error.
   */
  error: string | undefined;
  /**
   * HTTP status code,
   * hints the kind of error that happened.
   */
  status: number;
  /**
   * `true` if the signin was successful
   */
  ok: boolean;
  /**
   * `null` if there was an error,
   * otherwise the url the user
   * should have been redirected to.
   */
  url: string | null;
}

export const Login = (): JSX.Element => {
  const [waiting, setWaiting] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = async (input: LoginCredentials) => {
    setWaiting(true);
    setOpen(false);
    try {
      const res = ((await signIn('company-member-login', {
        ...input,
        redirect: false,
      })) as unknown) as SignInResponse;

      if (!res.ok) {
        setOpen(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setWaiting(false);
    }
  };

  return (
    <>
      <LoginForm onSubmit={handleSubmit} waiting={waiting} />
      <Snackbar
        message="認証に失敗しました。"
        severity="error"
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};
